<template>
    <div>
        <Loader :isLoading="isLoading" />

        <div class="commen-hader">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <h2>PLAN PRICING</h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="populer-cat">
            <div class="container">
                <div class="mb-5" v-if="!currentPlan">
                    <h2>MOST POPULAR PRICING PLANS</h2>
                    <p>
                        Select one of these plans to enjoy a year of features from our site.
                    </p>

                    <div v-if="ress && ress.success" class="text-danger">
                        {{ ress.success[0] }}
                    </div>
                </div>
                <div class="mb-5" v-else>
                    <h2>MY PLANS</h2>
                    <p>
                        Select one of these plans to enjoy a year of features from our site.
                        <br />
                        Upgrade your current plan to benefit much more.
                    </p>
                    <hr />
                    <CurrentPlanDetails />

                    <h4 class="mt-4" v-if="allowUpgrade(currentPlan)">
                        Upgrade your plan
                    </h4>

                    <div v-if="ress && ress.success" class="text-danger">
                        {{ ress.success[0] }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-4 px-0" v-for="plan in plans" :key="plan.id">
                        <div :class="['card', { 'bg-success': isActivePlan(plan) }]" v-if="plan">
                            <div class="card-header">
                                <h3 v-if="!isActivePlan(plan)">
                                    {{ plan.plans_name }}
                                </h3>
                                <h3 class="text-white" v-else>
                                    {{ plan.plans_name }}
                                    <span class="badge small badge-light">Active</span>
                                </h3>
                            </div>

                            <ul
                                class="list-group plan-details-container list-group-flush text-center"
                            >
                                <li class="list-group-item" style="min-height: 100px">
                                    <i class="fa fa-rupee" style="font-size:30px;color:red">
                                        {{ Math.floor(plan.price / 12) }} </i
                                    ><span> / month</span> <br /><b>{{ plan.price_title }}</b>
                                </li>

                                <li class="list-group-item">
                                    <i class="fa fa-check-square mr-2" style="color: green;"></i>
                                    <label for="">No of user account:</label>
                                    <span style="color:red">
                                        {{ plan.user }}
                                    </span>
                                </li>

                                <li class="list-group-item">
                                    <i class="fa fa-check-square mr-2" style="color: green;"></i>
                                    <label for="">Post per month per user:</label>
                                    <span style="color:red">
                                        {{ plan.per_month }}
                                    </span>
                                </li>

                                <li class="list-group-item">
                                    <i class="fa fa-check-square mr-2" style="color: green;"></i>
                                    <label for="">Each Post available on portal (Days):</label>
                                    <span style="color:red">
                                        {{ plan.days }}
                                    </span>
                                </li>

                                <li class="list-group-item">
                                    <i class="fa fa-check-square mr-2" style="color: green;"></i>
                                    <label for="">Surplus posting per month:</label>
                                    <span style="color:red">
                                        {{ plan.surplus_per_month }}
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <i class="fa fa-check-square mr-2" style="color: green;"></i>
                                    <label for="">Viewing Post on the portal:</label>
                                    <span style="color:red">
                                        {{ getFeature(plan.viewing_portal) }}
                                    </span>
                                </li>

                                <li class="list-group-item">
                                    <i class="fa fa-check-square mr-2" style="color: green;"></i>
                                    <label for="">Post Storage:</label>
                                    <span style="color:red">
                                        {{ getFeature(plan.storage) }}
                                    </span>
                                </li>

                                <li class="list-group-item">
                                    <i
                                        v-if="!isFeatureAllowed(plan.report)"
                                        class="fa fa-window-close mr-2 mr-2"
                                        style="color: red;"
                                    ></i>
                                    <i
                                        v-else
                                        class="fa fa-check-square mr-2"
                                        style="color: green;"
                                    ></i>
                                    <label for="">Analytics and report:</label>
                                    <span style="color:red">
                                        {{ getFeature(plan.report) }}
                                    </span>
                                </li>

                                <li class="list-group-item">
                                    <i class="fa fa-check-square mr-2" style="color: green;"></i>
                                    <label for="">Access to directory:</label>
                                    <span style="color:red">
                                        {{ getFeature(plan.directory) }}
                                    </span>
                                </li>

                                <li class="list-group-item">
                                    <i class="fa fa-check-square mr-2" style="color: green;"></i>
                                    <label for="">MEP Feed and blog:</label>
                                    <span style="color:red">
                                        {{ getFeature(plan.blog) }}
                                    </span>
                                </li>

                                <li class="list-group-item">
                                    <i class="fa fa-check-square mr-2" style="color: green;"></i>
                                    <label for="">Job Portal:</label>
                                    <span style="color:red">
                                        {{ getFeature(plan.portal) }}
                                    </span>
                                </li>

                                <li class="list-group-item">
                                    <i class="fa fa-check-square mr-2" style="color: green;"></i>
                                    <label for="">Job posting per month:</label>
                                    <span style="color:red">
                                        {{ plan.jobs_per_month }}
                                    </span>
                                </li>

                                <li class="list-group-item">
                                    <i class="fa fa-check-square mr-2" style="color: green;"></i>
                                    <label for="">MEP Inspector:</label>
                                    <span style="color:red">
                                        {{ getFeature(plan.inspector) }}
                                    </span>
                                </li>

                                <li class="list-group-item">
                                    <i
                                        v-if="!isFeatureAllowed(plan.listing)"
                                        class="fa fa-window-close mr-2"
                                        style="color: red;"
                                    ></i>
                                    <i
                                        v-else
                                        class="fa fa-check-square mr-2"
                                        style="color: green;"
                                    ></i>
                                    <label for="">Premium listing of Post:</label>
                                    <span style="color:red">
                                        {{ getFeature(plan.listing) }}
                                    </span>
                                </li>

                                <li class="list-group-item">
                                    <i
                                        v-if="!isFeatureAllowed(plan.tag)"
                                        class="fa fa-window-close mr-2"
                                        style="color: red;"
                                    ></i>
                                    <i
                                        v-else
                                        class="fa fa-check-square mr-2"
                                        style="color: green;"
                                    ></i>
                                    <label for="">Verified Tag:</label>
                                    <span style="color:red">
                                        {{ getFeature(plan.tag) }}
                                    </span>
                                </li>

                                <li class="list-group-item">
                                    <i class="fa fa-check-square mr-2" style="color: green;"></i>
                                    <label for="">Customer Care support:</label>
                                    <span style="color:red">
                                        {{ getFeature(plan.support) }}
                                    </span>
                                </li>

                                <li class="list-group-item">
                                    <i
                                        v-if="!isFeatureAllowed(plan.marketing)"
                                        class="fa fa-window-close mr-2"
                                        style="color: red;"
                                    ></i>
                                    <i
                                        v-else
                                        class="fa fa-check-square mr-2"
                                        style="color: green;"
                                    ></i>
                                    <label for="">Digital Marketing:</label>
                                    <span style="color:red">
                                        {{ getFeature(plan.marketing) }}
                                    </span>
                                </li>

                                <!-- <li class="list-group-item"> <input type="checkbox" class="form-check-input" id="exampleCheck1" checked> {{plan.user}} </li>
            <li class="list-group-item"> <input type="checkbox" class="form-check-input" id="exampleCheck1" checked> {{plan.per_month}} </li>
            <li class="list-group-item"> <input type="checkbox" class="form-check-input" id="exampleCheck1" checked> For {{plan.days}} </li>
            <li class="list-group-item"> <input type="checkbox" class="form-check-input" id="exampleCheck1" checked> {{plan.viewing_portal}} </li> -->

                                <br />
                                <div>
                                    Billed annually
                                    <br />
                                    <template v-if="isLogged">
                                        <template
                                            v-if="
                                                plan &&
                                                    currentPlan &&
                                                    currentPlan.plan.plans_name !== 'FREE' &&
                                                    !isActivePlan(plan)
                                            "
                                        >
                                            <router-link
                                                v-if="allowUpgrade(plan)"
                                                class="primary-button"
                                                :value="plan.id"
                                                v-on:click="planselect(plan.id)"
                                                :to="{
                                                    name: 'payment',
                                                    params: { id: plan.id },
                                                }"
                                                >Upgrade NOW for ₹{{
                                                    getUpgradePrice(plan)
                                                }}</router-link
                                            >
                                            <button
                                                class="primary-button disabled"
                                                v-else
                                                :disabled="true"
                                                style="cursor: not-allowed; opacity: 0.5"
                                            >
                                                No upgrade allowed
                                            </button>
                                        </template>
                                        <template v-else>
                                            <router-link
                                                v-if="!isActivePlan(plan)"
                                                class="btn log-btn"
                                                :value="plan.id"
                                                v-on:click="planselect(plan.id)"
                                                style="background-color:#8b0000; color: white; "
                                                :to="{
                                                    name: 'payment',
                                                    params: { id: plan.id },
                                                }"
                                                >Select NOW</router-link
                                            >
                                        </template>
                                    </template>
                                    <router-link
                                        v-if="!isLogged"
                                        class="btn log-btn"
                                        style="background-color:#8b0000; color: white; "
                                        to="register"
                                        >BUY NOW</router-link
                                    >

                                    <!-- <button href="javascript:void(0)" :value=plan.id class="btn log-btn" v-if="isLogged" style="background-color:#8b0000; color: white;" v-on:click="planselect(plan.id)"> Select NOW </button>

            <router-link class="btn log-btn" v-else style="background-color:#8b0000; color: white; " :to="{name : 'payment', params:{ id: plan.id}}">BUY NOW</router-link> -->
                                </div>
                                <br />
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import Loader from "./Loader"
import scrollTop from "./utils/scrollTop"
import request from "../apis/request"
import CurrentPlanDetails from "./utils/CurrentPlanDetails"
import { mapState, mapGetters, mapActions } from "vuex"

//import { HTTP } from "../_helper/http-constants";

//axios.defaults.withCredentials = true;

export default {
    name: "dashboard",
    components: { Loader, CurrentPlanDetails },
    computed: {
        ...mapState("auth", {
            isLogged: "authenticated",
        }),
        ...mapState("plan", {
            currentPlan: "currentPlan",
        }),
        ...mapGetters("plan", {
            getUpgradePrice: "getUpgradePrice",
            allowUpgrade: "allowUpgrade",
        }),
    },
    data() {
        return {
            ress: {},
            plans: null,
            plansid: "",
            isLoading: false,

            action: "/userplans",
        }
    },

    async mounted() {
        scrollTop()
        this.isLoading = true

        const response = await request.get("/plans")
        this.plans = response.data.result
        console.log("princing page is logged", this.isLogged)

        this.isLoading = false
    },

    methods: {
        isActivePlan(plan) {
            if (!this.currentPlan || !plan) return false
            return this.currentPlan.plan_id === plan.id
        },
        getFeature(feature) {
            return Number.isInteger(Number(feature)) && !Number(feature) ? "No" : feature
        },
        isFeatureAllowed(feature) {
            return Number.isInteger(Number(feature)) && !Number(feature) ? false : true
        },
        planselect(value) {
            //"value" is the clicked button value

            if (!this.isLogged) {
                return this.$toasted.error("Please login to continue")
            }

            this.plansid = value

            //console.log(plansid);

            const form = new FormData()

            form.append("plansid", this.plansid)

            request
                .post(this.action, form)
                .then((res) => {
                    console.log(form)
                    window.localStorage.setItem("isLogged", true)
                    window.localStorage.setItem("user", JSON.stringify(res.data))
                    //console.log("localstorage", JSON.parse(window.localStorage.getItem('user')));
                    //this.$router.push({name: ''});
                    console.log("ok")
                })

                .catch((res) => {
                    //alert('err');
                    if (res.response.status === 422) {
                        this.ress = res.response.data.errors || {}
                    }
                    return res
                })
        },
    },
    beforeDestroy() {
        this.isLoading = true
        console.log("pricing unmounting")
    },
}
</script>

<style lang="scss" scoped>
.plan-details-container {
    font-size: 0.8em;
    line-height: 1;
    .list-group-item {
        min-height: 80px;
    }
}
</style>
