<template>
    <div>
        <div class="my-3 font-weight-bold">Current Plan Details</div>
        <div class="card p-3">
            <div class="bg-success card-header text-white">
                {{ currentPlan.plan.plans_name }}
            </div>
            <div class="card-body text-left">
                <div class="row">
                    <div class="col-md-6">
                        <div v-if="isFreePlan">Days Left: N/A</div>
                        <div v-else>Days Left: {{ currentPlan.days_left }}</div>
                        <!-- <div>
                            Surplus Product posting per month: {{ currentPlan.plan.per_month }}
                        </div> -->
                        <!-- <div>Per AD lifetime: {{ currentPlan.plan.days }} days</div> -->
                        <div>
                            Surplus posting per month: {{ currentPlan.plan.surplus_per_month }}
                        </div>
                        <div v-if="!isFreePlan">
                            Purchase date: {{ new Date(currentPlan.start_date).toDateString() }}
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div>Upgradable: {{ allowUpgrade(currentPlan) ? "Yes" : "No" }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"
export default {
    computed: {
        ...mapState("plan", {
            currentPlan: "currentPlan",
        }),
        ...mapGetters("plan", {
            getUpgradePrice: "getUpgradePrice",
            allowUpgrade: "allowUpgrade",
            isFreePlan: "isFreePlan",
        }),
    },
}
</script>

<style></style>
